import { Grid, Card, CardContent, Box, Container } from "@mui/material"

import React, { ComponentType, ElementType, PropsWithChildren, Suspense, useRef, useState } from "react"
import Typography from "../Typography"

import {
    useHover,
    useFloating,
    useInteractions,
    useTransitionStyles,
    useTransitionStatus,
    autoPlacement,
    flip,
    detectOverflow,
    autoUpdate,
    shift,
    offset,
    FloatingPortal
} from '@floating-ui/react';

import gameData from "./gamesData"

type Props = { [prop: string]: any }

var GameCard = (props: Props) => {
    const data = props.data

    const [infoOpen, openInfo] = useState(false);

    const re = useRef(null)

    const { refs, context, floatingStyles } = useFloating({
        open: infoOpen,
        onOpenChange: openInfo,
        placement: "bottom"
    });

    const hover = useHover(context);

    const { getReferenceProps, getFloatingProps } = useInteractions([
        hover
    ])

    return (
        <Grid item p={{ sm: 1 }} gridAutoRows={2}>
            <Card ref={refs.setReference} {...getReferenceProps()}
                sx={{
                    backgroundImage: `url(${data.thumbnail})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    minHeight: 225,
                    minWidth: 400,
                    borderColor: data?.border?.color ? data.border.color : null,
                    borderStyle: data?.border?.style ? data.border.style : "solid",
                    borderWidth: data?.border?.width ? data.border.width : "2px",
                }}
            >
                
            </Card>
        </Grid>
    )
}

var GamesTab = () => {

    return (
        <Suspense>
            <Grid container>
                {
                    gameData.map((v) => {
                        return <GameCard key={v.gameName} data={v} />
                    })
                }
            </Grid>
        </Suspense>
    )
}

export default GamesTab