const data = [
    {
        gameName: "Azure Mines",
        thumbnail: "https://tr.rbxcdn.com/43958ca5280c145258f0c2af027a6efd/768/432/Image/Webp",
        border: {
            color: "rgb(28, 84, 255)"
        }
    }
]

export default data