import * as React from 'react';
import { useState } from 'react';
import { Avatar, Box, ButtonGroup, Card, CssBaseline, Divider, Grid, Paper, Tab, Tabs, Typography } from "@mui/material"
import {
    Outlet,
    useLoaderData
} from "react-router-dom";
import { Container, Stack } from '@mui/system';
import {
    useHover,
    useFloating,
    useInteractions,
    FloatingArrow,
    arrow,
    offset,
    useTransitionStyles,
    autoPlacement
} from "@floating-ui/react";
import { Canvas } from "@react-three/fiber"

//Component import
import T2 from "../components/Typography"
import Navbar from '../components/Navbar';
import { LinkedInButton, NPMButton, GithubButton, DiscordProfile, RobloxProfile } from '../components/MediaButtons';

//Tab Import
import GamesTab from '../components/tabs/GamesTab';

//Layout
import MainLayout from '../components/layouts/MainLayout';

//Style import
import "./style.scss"
import "../App.scss"

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Experience() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs centered value={value} onChange={handleChange} aria-label="tabs">
                    <Tab label="Games" sx={{textTransform: "none", fontFamily: "Inter", fontSize: "medium"}} {...a11yProps(0)} />
                    <Tab label="Item Two" sx={{textTransform: "none", fontFamily: "Inter", fontSize: "medium"}} {...a11yProps(1)} />
                    <Tab label="Item Three" sx={{textTransform: "none", fontFamily: "Inter", fontSize: "medium"}} {...a11yProps(2)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <GamesTab/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                Item Two
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                Item Three
            </CustomTabPanel>
        </Box>
    );
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function Home() {
    const t = useLoaderData()
    console.log(t)
    return (
        <Container disableGutters maxWidth="xl">
            <Paper elevation={1} sx={{ textAlign: "center" }}>
                <T2 fontSize={"larger"}>Projects / Contributions</T2>
                <Experience />
            </Paper>
        </Container>
    );
}

export default Home;
