var copyText = (text: string, cb?: (status: any, t: string) => void) => {
    navigator.clipboard.writeText(text)
    .then((v) => {
        cb(true, text)
    })
    .catch((reason) => {
        cb(false, reason)
    })
}

export default {copyText}